import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "En",
  cs: "Cs",
}

const Language = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => {
          if (language !== currentLocale) {
            return (
              <button
                key={language}
                className="lang"
                onClick={() => changeLocale(language)}
              >
                {languageName[language]}
              </button>
            )
          }
        })
      }
    </IntlContextConsumer>
  )
}

export default Language
