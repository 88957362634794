import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Footer from "../components/footer"
import { injectIntl, useIntl } from "gatsby-plugin-intl"

const Layout = ({ children }) => {
  const intl = useIntl()
  return (
    <main role="main">
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        keywords={[`Živlovka`, `hra`, `emoce`]}
      />
      {children}
      <Footer />
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
