import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Language from "../components/language"
import { useIntl } from "gatsby-plugin-intl"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Link } from "gatsby"

export default function Header() {
  const intl = useIntl()

  const [isActive, setActive] = useState({
    menuClass: "home",
    burgerClass: "hamburger hamburger--slider",
  })

  function setLinkActive(link) {
    setActive({
      ...isActive,
      menuClass: link,
    })
  }

  function toggleBurger() {
    const burgerClass = isActive.burgerClass.includes("is-active")
      ? "hamburger hamburger--slider"
      : "hamburger hamburger--slider is-active"
    setActive({
      ...isActive,
      burgerClass: burgerClass,
    })
  }

  return (
    <header>
      <div className="container">
        <nav>
          <div>
            <AnchorLink
              to="/#hero"
              className="logo"
              title={
                <FormattedMessage
                  id="title"
                  defaultMessage="Hra živlových bran"
                />
              }
              onAnchorLinkClick={() => {
                setLinkActive("home")
              }}
            />
          </div>
          <div className="menuItems">
            <button
              className={isActive.burgerClass}
              onClick={toggleBurger}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            <ul className="menu">
              <li>
                <AnchorLink
                  to="/#method"
                  title={intl.formatMessage({
                    id: "menu_link_1",
                    defaultMessage: "O metodě Živlovka",
                  })}
                  className={isActive.menuClass === "method" ? "active" : ""}
                  onAnchorLinkClick={() => {
                    setLinkActive("method")
                  }}
                />
              </li>
              <li>
                <AnchorLink
                  to="/#steps"
                  title={intl.formatMessage({
                    id: "menu_link_2",
                    defaultMessage: "Jak se Živlovka použiva",
                  })}
                  className={isActive.menuClass === "steps" ? "active" : ""}
                  onAnchorLinkClick={() => {
                    setLinkActive("steps")
                  }}
                />
              </li>
              <li>
                <AnchorLink
                  to="/#stories"
                  title={intl.formatMessage({
                    id: "menu_link_3",
                    defaultMessage: "Příběhy těch, co Živlovku vyzkouseli",
                  })}
                  className={isActive.menuClass === "stories" ? "active" : ""}
                  onAnchorLinkClick={() => {
                    setLinkActive("stories")
                  }}
                />
              </li>
              <li>
                <Link
                  to="/meetings"
                  // className={isActive.menuClass === "stories" ? "active" : ""}
                >
                  {intl.formatMessage({
                    id: "menu_link_4",
                  })}
                </Link>
              </li>
            </ul>
            <div className="menu-right">
              <a
                href="https://apps.apple.com/us/app/živlovka/id1542361955"
                className="icon-apple"
                target="_blank"
                rel="noreferrer"
              >
                app store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.zivlovka"
                className="icon-google"
                target="_blank"
                rel="noreferrer"
              >
                google play
              </a>
            </div>
            <Language />
          </div>
        </nav>
      </div>
    </header>
  )
}
