import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"

function SEO({ meta, lang, title, image }) {
  const intl = useIntl()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${intl.formatMessage({ id: "title" })}`}
      titleTemplate={`${intl.formatMessage({
        id: "title",
        defaultMessage: "Živlovka",
      })}`}
      meta={[
        {
          name: `description`,
          content: `${intl.formatMessage({
            id: "description",
            defaultMessage: "Ovládněte své emoce pomocí živlů",
          })}`,
        },
        {
          name: `author`,
          content: `${intl.formatMessage({
            id: "author",
            defaultMessage: "Code8",
          })}`,
        },
        {
          property: `og:title`,
          content: `${intl.formatMessage({
            id: "title",
            defaultMessage: "Živlovka",
          })}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `cz`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
