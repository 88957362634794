import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <h3>
          <FormattedMessage
            id="footer_game_title"
            defaultMessage="Živlovka: Hra živlových bran"
          />
        </h3>
        <div className="footer-links">
          <ul>
            <li>
              <Link to="/about">
                <FormattedMessage id="footer_link_about" />
              </Link>
            </li>
            <li>
              <a href="mailto: kouc@zivlovka.cz">
                <FormattedMessage
                  id="footer_link_coach"
                  defaultMessage="Mám zájem být živlovým průvodcem"
                />
              </a>
            </li>
            <li>
              <Link to="/terms">
                <FormattedMessage
                  id="footer_link_terms"
                  defaultMessage="Podmínky použití"
                />
              </Link>
            </li>
          </ul>
          <ul className="address">
            <li>AURIDEA, s.r.o</li>
            <li>Čestice 192, Čestice, 387 19</li>
            <li>IČO: 28133196, DIČ: CZ28133196</li>
            <a href="mailto: info@zivlovka.cz">
              <li>info@zivlovka.cz</li>
            </a>
          </ul>
          <ul>
            <a
              href="https://apps.apple.com/us/app/živlovka/id1542361955"
              className="icon-apple"
              target="_blank"
              rel="noreferrer"
            >
              app store
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.zivlovka"
              className="icon-google"
              target="_blank"
              rel="noreferrer"
            >
              google play
            </a>
          </ul>
        </div>
        <div className="copy">
          <div className="social-icons">
            <a
              href="https://www.facebook.com/zivlovka"
              className="icon-fb"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            {/* <a
              href="https://www.instagram.com"
              className="icon-ig"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a> */}
          </div>
          <p>
            <FormattedMessage
              id="footer_copy"
              defaultMessage="Všechna práva vyhrazena © 2021"
            />
          </p>
        </div>
      </div>
    </footer>
  )
}
